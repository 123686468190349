import Swiper from "swiper/bundle";
import "swiper/css/bundle";




const main_swiper = new Swiper('.swiper.main-swiper', {
  // Optional parameters

//  loop: true,

  slidesPerView: 3,
  spaceBetween: 16,

  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

const modalSwiper = new Swiper('.second-modal .swiper', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
        nextEl: '.right-control',
        prevEl: '.left-control',
      },
})

const gallery = new Swiper('#single-gallery .swiper', {
    slidesPerView: 4,
    spaceBetween: 16,
      scrollbar: {
          el: '.swiper-scrollbar',
        },
})