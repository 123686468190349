import { showBackdrop, hideBackdrop } from './utils.js';
document.querySelectorAll('.popup-open').forEach((button) => {
		button.addEventListener('click', () => {
				showBackdrop($(document.querySelector(`.backdrop.${button.classList[1]}`)), true);
				if(document.querySelector(`.backdrop.${button.classList[1]}`).querySelector('.close-button')){
				document.querySelector(`.backdrop.${button.classList[1]}`).querySelector('.close-button').addEventListener('click', ()=>{
				    hideBackdrop($(document.querySelector(`.backdrop.${button.classList[1]}`)))
				})
				}
		});
})